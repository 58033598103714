var fbk = (function (settings) {
	'use strict';
	return {
		//settings: settings,
		objects: objects,
		objectInfo: objectInfo,
		findServiceRecords: findServiceRecords,
		findRecords: findRecords,
		getRecord: getRecord,
		createQuery: createQuery,
		createRecord: createRecord,
		createServiceAppointmentRecord: createServiceAppointmentRecord,
		updateRecord: updateRecord,
		updateServiceAppointmentRecord: updateServiceAppointmentRecord,
		deleteRecord: deleteRecord,
		publish: publish,
		initialize: initialize,
		isSalesforce: isSalesforce,
		quickFind: quickFind,
		testFields: testFields,
		saveSchedules: saveSchedules,
		getUserInfo: getUserInfo,
		fields: fields,
		getUserPreferences: getUserPreferences,
		isSandbox: isSandbox,
		picklist: picklist,
		testPicklist: testPicklist,
		reloadPage: reloadPage,
		navigate: navigate,
		openURL: openURL,
		testObjects: testObjects,
		objectExists: objectExists,
		recordTypes: recordTypes,
		client: client,
		context: context,
		mapToArray: mapToArray,
		settings: settings,
		users: users,
		systemAdmins: systemAdmins,
		getUserRecordByName: getUserRecordByName,
		resourceObjects: resourceObjects,
		getIdByName: getIdByName,
		getServiceAppointmentResources: getServiceAppointmentResources,
	};

	function resourceObjects(resourceIdField, source, callback) {
		var objectName = source.objectName;
		fields(objectName, processFields);
		function processFields(data) {
			for (var i = 0; i < data.length; i++) {
				if (data[i].apiName === resourceIdField) {
					var objects = data[i].allReferences;
					callback(objects);
					break;
				}
			}
		}
	}

	function getIdByName(name, object, nameField, callback) {
		if (
			settings.resourceData &&
			settings.resourceData[name] &&
			settings.resourceData[name].object === object
		) {
			//we've already looked up the resource id this session, so pull from settings
			var result = {
				object: settings.resourceData[name].object,
				id: settings.resourceData[name].id,
			};
			callback(result);
			return;
		}
		var select = 'SELECT+Id,' + nameField + '+FROM+' + object;
		var where = 'WHERE+' + nameField + "='" + encodeName(name) + "'";
		var sr = settings.sr;
		var url = sr.context.links.restUrl + 'query/?q=' + select + '+' + where;
		Sfdc.canvas.client.ajax(url, {
			client: sr.client,
			success: processResult,
		});
		function processResult(data) {
			if (data && data.status === 200 && data.payload.totalSize > 0) {
				var id = data.payload.records[0].Id;
				var result = {
					object: object,
					id: id,
				};
				if (!settings.resourceData) {
					settings.resourceData = {};
					settings.resourceData[name] = {
						id: id,
						object: object,
					};
				}
				callback(result);
			} else {
				callback(false);
			}
		}
		function encodeName(name) {
			var result = encodeURIComponent(name);
			result = result.replace(/'/g, "\\'");
			return result;
		}
	}

	function getServiceAppointmentResources(query, callback) {
		var sr = settings.sr;
		var url = `${sr.context.links.restUrl}query/?q=${query}`;
		Sfdc.canvas.client.ajax(url, {
			client: sr.client,
			success: processResult,
		});
		function processResult(data) {
			if (data && data.status === 200 && data.payload.totalSize > 0) {
				callback(data.payload.records);
			} else {
				callback(false);
			}
		}
	}

	function client() {
		return settings.sr.client;
	}

	function context() {
		return settings.sr.context;
	}

	function recordTypes(object, callBack) {
		var requests = {
			SobjectType: object,
		};

		var select =
			'SELECT+Id,DeveloperName+FROM+RecordType+WHERE+SobjectType=' +
			"'" +
			object +
			"'" +
			'+ORDER BY DeveloperName';
		var result;

		var sr = settings.sr;
		var url = sr.context.links.restUrl + 'query/?q=' + select;
		Sfdc.canvas.client.ajax(url, {
			client: sr.client,
			success: function (data) {
				if (data.status === 200) {
					callBack(data.payload.records);
				} else if (data.status === 0) {
					result = [
						{
							errorCode:
								'No response from Salesforce. Check Internet Connection.',
							message:
								'No response from Salesforce. Check Internet Connection.',
						},
					];
					callBack(result);
					return;
				} else if (data.payload[0] && data.payload[0].errorCode) {
					result = [
						{
							errorCode: data.payload[0].errorCode,
							message:
								'Salesforce Error: ' +
								cleanError(data.payload[0].message),
						},
					];
					callBack(result);
					return;
				}
			},
		});
	}

	function objectExists(name, objects) {
		for (var property in objects.sobjects) {
			if (name === objects.sobjects[property].name) {
				return true;
			}
		}
		return false;
	}

	function navigate(id) {
		var url = '/' + id;
		publish('dbk.navigate', {id: id, url: url});
	}

	function openURL(url) {
		publish('dbk.navigate', {url: url, new: true});
	}

	function reloadPage() {
		publish('dbk.navigate', {url: settings.refresh, new: false});
	}

	function isSandbox() {
		var instance = settings.sr.client.instanceUrl;
		if (instance.indexOf('Sandbox') !== -1) {
			return true;
		} else {
			return false;
		}
	}

	//Get the current url parameter string. We can use this to pass properties view the iframe url
	function urlParams() {
		var match,
			pl = /\+/g, // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) {
				return decodeURIComponent(s.replace(pl, ' '));
			},
			query = window.location.search.substring(1),
			params = {};

		while ((match = search.exec(query))) {
			params[decode(match[1])] = decode(match[2]);
		}
		return params;
	}

	//Method of checking if we are currently running inside of salesforce or not

	function isSalesforce() {
		var type = urlParams().type;
		return type === 'salesforce';
	}

	function initialize(callBack) {
		var result = {};
		settings.userFields = [];

		if (!isSalesforce()) {
			result = {
				salesforce: false,
			};
			if (callBack) {
				callBack(result);
			}
			return;
		}

		var sr;
		var prof;
		var taskNotification;
		var eventNotification;

		//helper function

		function findInArray(a, prop, val) {
			var i;
			for (i in a) {
				if (a[i][prop] === val) {
					return a[i];
				}
			}
		}

		//begin calls to SF
		Sfdc.canvas.client.refreshSignedRequest(function (data) {
			if (data.status === 200) {
				var signedRequest = data.payload.response;
				var part = signedRequest.split('.')[1];
				sr = JSON.parse(Sfdc.canvas.decode(part));
				settings.sr = sr;
				//console.log(sr);
				//subscribe to url published from VF page
				Sfdc.canvas.client.subscribe(sr.client, {
					name: 'dbk.url',
					onData: function (e) {
						var hash = location.hash;
						var val = e.url;
						var path = e.location;
						location.hash = hash + val;
						settings.refresh = path + e.url;
					},
				});

				//now that we've subscribed, let's force the publish URL
				Sfdc.canvas.client.publish(settings.sr.client, {
					name: 'dbk.retriveURL',
				});

				//initialize canvas app size
				resize();

				//begin cascading calls to SF for user and org info
				fields('User', writeUserDescribe);
				//createResult();
			} else if (data.status === 0) {
				result = {
					errorCode:
						'No response from Salesforce. Check Internet Connection.',
					message:
						'No response from Salesforce. Check Internet Connection.',
				};
				callBack(result);
				return;
			} else if (data.payload[0] && data.payload[0].errorCode) {
				result = {
					errorCode: data.payload[0].errorCode,
					message:
						'Salesforce Error: ' +
						cleanError(data.payload[0].message),
				};
				callBack(result);
				return;
			}
		});

		//define callbacks these cascade until reminder Defaults calls the passed callBack function itself

		function writeUserDescribe(data) {
			var result;
			if (data[0] && data[0].errorCode) {
				result = {
					errorCode: data[0].errorCode,
					message: 'Salesforce Error: ' + cleanError(data[0].message),
				};
				callBack(result);
				return;
			}
			settings.userFields = data;

			if (sr.context.user.profileId) {
				getProfile(sr.context.user.profileId, createResult);
			} else {
				createResult();
			}
		}

		function getProfile(profileId, callback) {
			var query = "SELECT+Name+FROM+Profile+WHERE+Id='" + profileId + "'";
			var url = sr.context.links.restUrl + 'query/?q=' + query;
			var result = [];
			Sfdc.canvas.client.ajax(url, {
				client: sr.client,
				success: function (data) {
					if (data.status === 200) {
						callback(data);
					} else if (data.status === 0) {
						result = {
							errorCode:
								'No response from Salesforce. Check Internet Connection.',
							message:
								'No response from Salesforce. Check Internet Connection.',
						};
						callback(result);
						return;
					} else if (data.payload[0] && data.payload[0].errorCode) {
						result = {
							errorCode: data.payload[0].errorCode,
							message:
								'Salesforce Error: ' +
								cleanError(data.payload[0].message),
						};
						callback(result);
						return;
					}
				},
			});
		}

		function createResult(data) {
			if (data.payload && data.payload.records.length > 0) {
				settings.userProfile = data.payload.records[0].Name;
			} else {
				settings.userProfile = 'n/a';
			}

			result.salesforce = true;
			result.accountName = sr.context.user.userName;
			result.accountId = sr.context.user.userId;
			//result.accountProfile = prof;
			result.taskNotification = taskNotification;
			result.eventNotification = eventNotification;
			result.accountFirstName = sr.context.user.firstName;
			result.accountLastName = sr.context.user.lastName;
			result.accountFullName = sr.context.user.fullName;
			result.accountEmail = sr.context.user.email;
			result.accountTimeZone = sr.context.user.timeZone;
			result.accountLocale = sr.context.user.locale;

			settings.userInfo = result;

			settings.userNames = {};
			settings.userIds = {};

			settings.userNames[sr.context.user.fullName] = result;
			settings.userIds[sr.context.user.userId] = result;

			result.organizationName = sr.context.organization.name;
			result.organizationId = sr.context.organization.organizationId;
			result.organizationUsers = users;

			//if this user has either notifiction on, then we want to get the lead times.
			if (result.taskNotification || result.eventNotification) {
				reminderDefaults(result.accountId);
			} else {
				callBack(result);
			}
		}

		function reminderDefaults(id) {
			userPreference(id, updateDefaults);
			function updateDefaults(data) {
				//we only get a result to this query if the defaults have been entered
				var taskTime = 480;
				var eventTime = 15;
				var i;
				if (data) {
					for (i in data) {
						if (data[i].Preference === '57') {
							eventTime = data[i].Value;
						} else if (data[i].Preference === '58') {
							taskTime = data[i].Value;
						}
					}
				}
				settings.userInfo.eventReminderLeadTime = eventTime;
				settings.userInfo.taskReminderTime = taskTime;
				callBack(result);
			}
		}

		//end call backs
		//run our object initialization here
	} // end initialize

	function resize() {
		Sfdc.canvas.client.publish(settings.sr.client, {
			name: 'dbk.resize',
		});
	}

	function getUserRecordByName(name, callback) {
		if (settings.userNames[name]) {
			callback(settings.userNames[name]);
		} else {
			users(
				internalCallBack,
				1,
				"Name='" + encodeURIComponent(name).replace(/'/g, "\\'") + "'"
			);
		}
		function internalCallBack(data) {
			if (data[0] && data[0].Id) {
				//cache this result so we only need to query it once per session
				settings.userNames[name] = data[0];
				settings.userIds[data[0].Id] = data[0];
			}
			callback(data);
		}
	}

	function users(userLimit, where) {
		return new Promise((resolve, reject) => {
			//let's do the describe first to verify the fields we can query.
			var validFields = [];
			var c = 0;
			var i = 0;
			var thisField;

			//'foo' included as test, should never be included in query
			var baseFields = [
				'Id',
				'Username',
				'Name',
				'Alias',
				'BannerPhotoUrl',
				'Email',
				'FirstName',
				'LastName',
				'Department',
				'Phone',
				'MobilePhone',
				'Profile.Name',
				'UserPreferencesTaskRemindersCheckboxDefault',
				'UserPreferencesEventRemindersCheckboxDefault',
				'foo',
			];

			function getValidFields(field) {
				var c;
				for (c in settings.userFields) {
					if (
						(field === 'Profile.Name' &&
							settings.userFields[c].apiName === 'ProfileId') ||
						field === settings.userFields[c].apiName
					) {
						return field;
					}
				}
			}

			//need to validate these against those retrieved from fields/Describe call
			for (i in baseFields) {
				thisField = getValidFields(baseFields[i]);
				if (thisField) {
					validFields.push(getValidFields(baseFields[i]));
				}
			}

			var sel = 'SELECT+' + validFields.join(',');
			sel +=
				'+FROM+User+WHERE+IsActive=true+' +
				(where ? 'AND+' + where + '+' : '') +
				'Order+By+Name+Limit+' +
				userLimit;
			var url = settings.sr.context.links.restUrl + 'query/?q=' + sel;
			Sfdc.canvas.client.ajax(url, {
				client: settings.sr.client,
				success: function (data) {
					if (data.status === 200) {
						resolve(data.payload.records);
					} else if (data.status === 0) {
						const result = {
							errorCode:
								'No response from Salesforce. Check Internet Connection.',
							message:
								'No response from Salesforce. Check Internet Connection.',
						};
						reject(result);
					} else if (data.payload[0] && data.payload[0].errorCode) {
						const result = {
							errorCode: data.payload[0].errorCode,
							message:
								'Salesforce Error: ' +
								cleanError(data.payload[0].message),
						};
						reject(result);
					}
				},
			});
		});
	}

	function systemAdmins() {
		return new Promise((resolve, reject) => {
			//let's do the describe first to verify the fields we can query.
			var validFields = [];
			var c = 0;
			var i = 0;
			var thisField;
			var isAdmin = false;

			//'foo' included as test, should never be included in query
			var baseFields = [
				'Id',
				'Username',
				'Name',
				'Alias',
				'BannerPhotoUrl',
				'Email',
				'FirstName',
				'LastName',
				'Department',
				'Phone',
				'MobilePhone',
				'Profile.Name',
				'UserPreferencesTaskRemindersCheckboxDefault',
				'UserPreferencesEventRemindersCheckboxDefault',
				'foo',
			];

			function getValidFields(field) {
				var c;
				for (c in settings.userFields) {
					if (
						(field === 'Profile.Name' &&
							settings.userFields[c].apiName === 'ProfileId') ||
						field === settings.userFields[c].apiName
					) {
						return field;
					}
				}
			}

			//need to validate these against those retrieved from fields/Describe call
			for (i in baseFields) {
				thisField = getValidFields(baseFields[i]);
				if (thisField) {
					validFields.push(getValidFields(baseFields[i]));
					if (thisField === 'Profile.Name') {
						isAdmin = true;
					}
				}
			}

			if (!isAdmin) {
				resolve({
					status: 200,
					payload: {
						records: [],
					},
				});
				return;
			}

			var sel = 'SELECT+' + validFields.join(',');
			sel +=
				"+FROM+User+WHERE+IsActive=true+AND+Profile.Name+=+'System Administrator'+Order+By+Name";
			var url = settings.sr.context.links.restUrl + 'query/?q=' + sel;
			Sfdc.canvas.client.ajax(url, {
				client: settings.sr.client,
				success: function (data) {
					if (data.status === 200) {
						resolve(data.payload.records);
					} else if (data.status === 0) {
						const result = {
							errorCode:
								'No response from Salesforce. Check Internet Connection.',
							message:
								'No response from Salesforce. Check Internet Connection.',
						};
						resolve([]);
					} else if (data.payload[0] && data.payload[0].errorCode) {
						const result = {
							errorCode: data.payload[0].errorCode,
							message:
								'Salesforce Error: ' +
								cleanError(data.payload[0].message),
						};
						resolve([]);
					}
				},
			});
		});
	}

	function getUserPreferences(id, callBack) {
		var i;
		var result = {};
		var user = settings.userIds[id];
		if (!user) {
			user = {};
			settings.userIds[id] = user;
			userData(id, updateDefaults);
			return;
		}
		if (user.eventLeadTime) {
			//we have loaded this account's prefs already, so just load those
			result.taskRemindersOn =
				user.UserPreferencesTaskRemindersCheckboxDefault;
			result.eventRemindersOn =
				user.UserPreferencesEventRemindersCheckboxDefault;
			result.eventLeadTime = user.eventLeadTime;
			result.taskLeadTime = user.taskLeadTime;
			callBack(result);
			return;
		} else {
			//we need to query salesforce for this
			userData(id, updateDefaults);
		}

		function updateDefaults(data) {
			if (data.status === 0) {
				result = {
					errorCode:
						'No response from Salesforce. Check Internet Connection.',
					message:
						'No response from Salesforce. Check Internet Connection.',
				};
				callBack(result);
				return;
			} else {
				data = data.payload.records;
			}
			//we only get a result to this query if the defaults have been entered
			var taskTime = 480;
			var eventTime = 15;
			var i;
			if (data) {
				for (i in data) {
					if (data[i].Preference === '57') {
						eventTime = data[i].Value;
					} else if (data[i].Preference === '58') {
						taskTime = data[i].Value;
					}
				}
			}
			user.eventLeadTime = eventTime;
			user.taskLeadTime = taskTime;
			result.taskRemindersOn =
				user.UserPreferencesTaskRemindersCheckboxDefault;
			result.eventRemindersOn =
				user.UserPreferencesEventRemindersCheckboxDefault;
			result.eventLeadTime = user.eventLeadTime;
			result.taskLeadTime = user.taskLeadTime;
			callBack(result);
		}
		function userPreference(id, callBack) {
			var sr = settings.sr;
			var sel = 'SELECT+Preference,Value';
			sel += "+FROM+UserPreference+WHERE+UserId+=+'" + id + "'";
			var url = sr.context.links.queryUrl + '?q=' + sel;
			Sfdc.canvas.client.ajax(url, {
				client: sr.client,
				success: function (data) {
					callBack(data);
				},
			});
		}

		function userData(userId, callback) {
			var sr = settings.sr;
			var sel =
				'SELECT+UserPreferencesTaskRemindersCheckboxDefault,UserPreferencesEventRemindersCheckboxDefault';
			sel += "+FROM+User+WHERE+Id+=+'" + userId + "'";
			var url = sr.context.links.queryUrl + '?q=' + sel;
			Sfdc.canvas.client.ajax(url, {
				client: sr.client,
				success: function (data) {
					if (
						data &&
						data.status === 200 &&
						data.payload.totalSize > 0
					) {
						settings.userIds[
							userId
						].UserPreferencesTaskRemindersCheckboxDefault =
							data.payload.records[0].UserPreferencesTaskRemindersCheckboxDefault;
						settings.userIds[
							userId
						].UserPreferencesEventRemindersCheckboxDefault =
							data.payload.records[0].UserPreferencesEventRemindersCheckboxDefault;
					}
					userPreference(userId, callback);
				},
			});
		}
	}

	function testUserPreference() {
		userPreference(null, callBack);
		function callBack(d) {
			console.log(JSON.stringify(d, null, 2));
		}
	}

	//function for retrieving all available objects

	function objects(callBack) {
		return new Promise((resolve, reject) => {
			var sr = settings.sr;
			var result;
			var url = sr.context.links.sobjectUrl;
			Sfdc.canvas.client.ajax(url, {
				client: sr.client,
				success: function (data) {
					if (data.status === 200) {
						result = data.payload;
					} else if (data.status === 0) {
						result = {
							errorCode:
								'No response from Salesforce. Check Internet Connection.',
							message:
								'No response from Salesforce. Check Internet Connection.',
						};
					} else if (data.payload[0] && data.payload[0].errorCode) {
						result = {
							errorCode: data.payload[0].errorCode,
							message:
								'Salesforce Error: ' +
								cleanError(data.payload[0].message),
						};
					}
					if (callBack) {
						callBack(data.payload);
					}
					resolve(result);
				},
			});
		});
	}

	function testObjects() {
		function callBack(d) {
			console.log(d);
		}
		objects(callBack);
	}

	//function for retrieving metadata and fields for the specified object

	function objectInfo(objectName, callBack) {
		return new Promise((resolve, reject) => {
			if (!objectName) {
				return;
			}
			var sr = settings.sr;
			var url =
				sr.context.links.sobjectUrl +
				objectName +
				'/describe/' +
				'?nocache=' +
				new Date().getTime();
			Sfdc.canvas.client.ajax(url, {
				client: sr.client,
				success: function (data) {
					if (callBack) {
						callBack(data);
					} else {
						if (data?.status !== 200) {
							reject(data);
						} else {
							resolve(data);
						}
					}
				},
			});
		});
	}

	function testObjectInfo() {
		function callBack(d) {
			var i;
			var result = [];
			for (i in d) {
				result.push({
					apiName: d[i].name,
					label: d[i].name,
					type: d[i].type,
					updateable: d[i].updateable,
				});
			}
		}
		objectInfo('Account', callBack);
	}

	//field info by object name

	function fields(object, callBack, typeArray) {
		return new Promise((resolve, reject) => {
			function filter(d) {
				if (d.status != 200) {
					if (callBack) {
						callBack(d.payload);
					}
					reject(d.payload);
					return;
				}
				var i;
				var result = [];
				for (i in d.payload.fields) {
					if (
						!typeArray ||
						inArray(typeArray, d.payload.fields[i].type)
					) {
						result.push({
							apiName: d.payload.fields[i].name,
							label: d.payload.fields[i].label,
							type: d.payload.fields[i].type,
							nameField: d.payload.fields[i].nameField,
							updateable: d.payload.fields[i].updateable,
							picklistValues: d.payload.fields[i].picklistValues,
							restrictedPicklist:
								d.payload.fields[i].restrictedPicklist,
							defaultValue: d.payload.fields[i].defaultValue,
							defaultValueFormula:
								d.payload.fields[i].defaultValueFormula,
							relationshipName:
								d.payload.fields[i].relationshipName,
							allReferences: d.payload.fields[i].referenceTo,
							referenceTo: d.payload.fields[i].referenceTo[0]
								? d.payload.fields[i].referenceTo[0]
								: '',
							length: d.payload.fields[i].length,
						});
					}
				}
				settings.fields[object] = result;
				if (callBack) {
					callBack(result);
				}
				resolve(result);
			}
			objectInfo(object, filter);
		});
	}

	function testFields(object) {
		function callBack(d) {
			console.log(JSON.stringify(d, null, 2));
		}
		fields(object, callBack);
	}

	function picklist(object, field, callBack) {
		fields(object, processResult);
		function processResult(data) {
			if (data[0] && data[0].errorCode) {
				callBack(data);
				return;
			}
			var i;
			var result = false;
			for (i in data) {
				if (data[i].apiName === field) {
					if (data[i].picklistValues.length > 0) {
						result = {};
						result.picklist = data[i].picklistValues;
						result.restricted = data[i].restrictedPicklist;
					}
				}
			}
			callBack(result);
			return;
		}
	}

	function testPicklist() {
		picklist('Task', 'Status', callBack);
		function callBack(data) {
			console.log(data);
		}
	}

	//function for doing a quick find in the specified fields

	function quickFind(
		objectName,
		searchField,
		displayField,
		criteria,
		callBack,
		firstWordOnly
	) {
		var sr = settings.sr;
		var url;

		if (searchField === 'Name') {
			criteria = criteria.replace(/\%/g, '*');
			var q;
			url = sr.context.links.searchUrl + '?q=';
			//criteria = '"' + criteria + '"';
			q =
				'FIND {' +
				criteria +
				'} IN Name Fields RETURNING ' +
				objectName +
				'(' +
				displayField +
				',Id)';
			url += q;
		} else {
			//otherwise use SOQL
			//encode single quotes so they don't break the SOQL
			criteria = criteria.replace(/'/g, '\\' + "'");
			url = sr.context.links.queryUrl + '?q=';
			var sel;
			sel = 'SELECT+Id,' + displayField + '+FROM+' + objectName;
			//where logic
			if (firstWordOnly) {
				//limit SOQL query to the first word of the search for performance
				//
				sel +=
					'+WHERE+' +
					searchField +
					'+LIKE+' +
					encodeURIComponent("'" + criteria + '%' + "'") +
					'+OR+' +
					searchField +
					'+LIKE+' +
					encodeURIComponent("'the " + criteria + '%' + "'") +
					'+OR+' +
					searchField +
					'+LIKE+' +
					encodeURIComponent("'an " + criteria + '%' + "'") +
					'+OR+' +
					searchField +
					'+LIKE+' +
					encodeURIComponent("'a " + criteria + '%' + "'") +
					'+ORDER+BY+' +
					displayField;
			} else {
				sel +=
					'+WHERE+' +
					searchField +
					'+LIKE+' +
					encodeURIComponent("'" + criteria + '%' + "'") +
					'+OR+' +
					searchField +
					'+LIKE+' +
					encodeURIComponent("'% " + criteria + '%' + "'") +
					'+ORDER+BY+' +
					displayField;
			}
			url += sel;
		}

		//Make first call
		Sfdc.canvas.client.ajax(url, {
			client: sr.client,
			success: function (data) {
				if (data.status === 200) {
					//console.log(JSON.stringify(data.payload["records"],null,2));
					getResults(data.payload);
				}
			},
		});

		function getResults(d) {
			var records = [];
			processResult(d);
			function processResult(d) {
				var result = d.records ? d.records : d.searchRecords;
				if (d.searchRecords) {
					//need to sort these
					d.searchRecords.sort(sortObjects);
				}
				for (var i = 0; i < result.length; i++) {
					records.push(result[i]);
					if (i > 500) {
						break;
					}
				}
				callBack(records);
			}
			function sortObjects(a, b) {
				if (a.Name < b.Name) {
					return -1;
				}
				if (a.Name > b.Name) {
					return 1;
				}
				return 0;
			}
		}
	}

	function getRecord(objectName, id, callback) {
		var sr = settings.sr;
		var url = sr.context.links.sobjectUrl + objectName + '/' + id;

		Sfdc.canvas.client.ajax(url, {
			client: sr.client,
			success: function (data) {
				if (data.status === 200) {
					getResults(data.payload);
				}
			},
		});

		function getResults(data) {
			callback(data);
		}
	}

	function findServiceRecords(
		objectName,
		callback,
		requests,
		scheduleID,
		clause,
		resourceQuery
	) {
		const promises = [findEventRecords(), findSericeAppointmentResources()];

		function findEventRecords() {
			return new Promise((resolve, reject) => {
				//get events
				findRecords(
					objectName,
					(eventResult) => {
						resolve(eventResult);
					},
					requests,
					scheduleID,
					clause
				);
			});
		}

		function findSericeAppointmentResources() {
			return new Promise((resolve, reject) => {
				getServiceAppointmentResources(resourceQuery, (resources) => {
					const serviceResources = {};
					if (!resources) {
						resolve();
						return;
					}
					for (const resource of resources) {
						if (!serviceResources[resource.ServiceAppointment.Id]) {
							serviceResources[resource.ServiceAppointment.Id] =
								resource.ServiceResource.Name;
						} else {
							serviceResources[resource.ServiceAppointment.Id] +=
								`;${resource.ServiceResource.Name}`;
						}
					}
					resolve(serviceResources);
				});
			});
		}

		Promise.all(promises)
			.then((result) => {
				// merge data into event array
				const events = result[0];
				const resources = result[1];

				if (resources) {
					for (const event of events) {
						if (resources[event.Id]) {
							event.resource = resources[event.Id];
						}
					}
				}
				callback(events);
			})
			.catch((err) => {
				callback([]);
			});
	}

	//function for retrieving array of records from the specified object
	//fields returned for the passed object are specified in the schedule.fieldmap
	//queries use the "request" syntax where an array of objects are passed and each object represents an OR clause containg 1-n AND clauses
	//queroes can be constructed using fbk property names specified in the settings.mappingOut object in the IFFE argument

	function findRecords(
		objectName,
		callBack,
		requests,
		scheduleId,
		additionalWhere,
		queryOverride
	) {
		if (!settings.fields[objectName] && additionalWhere) {
			fields(objectName, function () {
				findRecords(
					objectName,
					callBack,
					requests,
					scheduleId,
					additionalWhere
				);
			});
			return;
		}

		console.log('requests', requests);

		var q;
		//transform requests to WHERE clause
		if (queryOverride) {
			q = queryOverride;
		} else if (requests) {
			q = createQuery(requests, objectName, scheduleId);
		} else {
			q = '';
		}
		console.log('requests q', q);
		//values from settings
		if (!settings.sr.context) {
			return;
		}
		var sr = settings.sr;

		//var sel = settings.mappingIn[objectName].select();

		var sel = createSelect();

		if (additionalWhere) {
			q += additionalWhere;
		}

		var url = sr.context.links.restUrl + 'query/?q=' + sel + q;
		//Make first call
		Sfdc.canvas.client.ajax(url, {
			client: sr.client,
			success: function (data) {
				if (data.status && data.payload) {
					//console.log(JSON.stringify(data.payload["records"],null,2));
					getResults(data.payload);
				} else {
					var result = [
						{
							errorCode:
								'No response from Salesforce. Check Internet Connection.',
							message:
								'No response from Salesforce. Check Internet Connection.',
						},
					];
					callBack(result);
					return;
				}
			},
		});

		function getResults(d) {
			var records = [];
			processResult(d);
			function processResult(d) {
				var result = d.records ? d.records : d;

				//Add the result to the records array
				for (var i = 0; i < result.length; i++) {
					records.push(result[i]);
				}
				if (d.done === false) {
					//additional results initiate next call
					var nUrl = d.nextRecordsUrl;
					Sfdc.canvas.client.ajax(nUrl, {
						client: sr.client,
						success: function (data) {
							if (data.status) {
								processResult(data.payload);
							}
						},
					});
				} else {
					//We are done paging results so send the returned records to our callback
					callBack(records);
				}
			}
		}

		function createSelect() {
			//get our field mapping for this object
			var fieldMap;
			var schedule;
			var result = '';
			for (var i in settings.schedules) {
				if (settings.schedules[i].id === scheduleId) {
					fieldMap = settings.schedules[i].fieldMap;
					schedule = settings.schedules[i];
				}
			}
			var props = mapToArray(schedule);
			for (var c in props) {
				if (result.length === 0) {
					result += 'SELECT+' + props[c];
				} else {
					result += ',' + props[c];
				}
			}
			return result + '+FROM+' + objectName + '+';
		}
	}

	//helper function to convert a field map to a de-duped array of fields
	function mapToArray(schedule) {
		var fieldMap = schedule.fieldMap;
		var c;
		var i;
		var thisProp;
		var props = [];
		var newProp;
		var titleProperties = [];

		if (!schedule.unusedMap) {
			schedule.unusedMap = {};
		}

		for (c in fieldMap) {
			if (schedule.useAssignedResources && c === 'resource') {
				continue;
			}
			if (fieldMap[c] && !schedule.unusedMap[c]) {
				if (
					!fieldMap[c].allowedSchedules ||
					fieldMap[c].allowedSchedules.schedule.objectName
				) {
					thisProp = fieldMap[c].split(',');
					for (i in thisProp) {
						props.push(thisProp[i].trim());
					}
				}
			}
		}

		//strip out tags
		var firstSplit;
		var secondSplit;
		var thirdSplit;
		var splitProps = [];
		for (i = 0; i < props.length; i++) {
			firstSplit = props[i].split('>');
			if (firstSplit.length > 1) {
				var fieldArray = [];
				for (var ii = 0; ii < firstSplit.length; ii++) {
					secondSplit = firstSplit[ii].split('</');
					if (secondSplit.length > 1 && secondSplit[0]) {
						splitProps.push(secondSplit[0]);
					}
				}
			} else {
				splitProps.push(props[i]);
			}
		}
		var used = [];
		var result = [];
		for (c in splitProps) {
			newProp = splitProps[c];
			//don't add to query if already added or if designated as unused
			if (used.indexOf(newProp) === -1) {
				result.push(newProp);
				used.push(newProp);
			}
		}
		//sort this by descending length, so we don't sub out any substrings
		return result.sort(compareLength);

		function compareLength(a, b) {
			return b.length - a.length;
		}
	}

	//helper function for findRecords().
	//Creates a formatted SOQL WHERE clause from an array of objects.
	//Public function for testing and demonstration.

	function createQuery(requests, objectName, scheduleId) {
		var fieldMap;
		var schedule;
		var i = 0;
		for (i in settings.schedules) {
			if (settings.schedules[i].id === scheduleId) {
				fieldMap = settings.schedules[i].fieldMap;
				schedule = settings.schedules[i];
			}
		}

		function createOrClause(o) {
			var oProps = Object.getOwnPropertyNames(o);
			var c = 0;
			var result = '';
			var line = '';
			var sourceProp = '';
			var sfProp = '';
			var value = '';
			for (c in oProps) {
				sourceProp = oProps[c];
				value = o[sourceProp];
				var rangeSplit = value.split('...');
				if (rangeSplit.length === 2) {
					line =
						fieldMap[sourceProp] +
						' > ' +
						encodeURIComponent(rangeSplit[0]) +
						'+AND+' +
						fieldMap[sourceProp] +
						' < ' +
						encodeURIComponent(rangeSplit[1]);
					if (schedule.recordType) {
						line +=
							" AND+(+RecordTypeId='" +
							schedule.recordType +
							"'+)";
					}
				} else {
					//if we're trying to define end, and it's not mapped, then set it to start.
					if (sourceProp === 'end' && !fieldMap[sourceProp]) {
						sourceProp = 'start';
					}
					//translate to SF properties
					if (fieldMap[sourceProp]) {
						sourceProp = fieldMap[sourceProp];
					}
					line = sourceProp + '+' + encodeURIComponent(value);
					if (schedule.recordType) {
						line +=
							" AND+(+RecordTypeId='" +
							schedule.recordType +
							"'+)";
					}
				}
				if (c > 0) {
					result += '+AND+' + line;
				} else {
					result += '+(+' + line;
				}
			}
			return result + '+)+';
		}

		//initialize as array if single object passed.
		if (typeof requests[0] !== 'object' && typeof request === 'object') {
			requests = [requests];
		}
		var line = '';
		var result = '';
		i = 0;
		for (i in requests) {
			line = createOrClause(requests[i]);
			if (i > 0) {
				result += '+OR+' + line;
			} else {
				result = '+WHERE+(+' + line;
			}
		}

		//constrain to record types if specified
		return result + '+)+';
	}

	function createServiceAppointmentRecord(
		objectName,
		callBack,
		request,
		scheduleId
	) {
		//sr and base url are in settings.
		//values from settings
		if (!settings.sr.context) {
			return;
		}
		var sr = settings.sr;
		var url = `${sr.context.links.restUrl}connect/scheduling/service-appointments/`;
		//Make call
		Sfdc.canvas.client.ajax(url, {
			client: sr.client,
			contentType: 'application/json',
			method: 'POST',
			data: JSON.stringify(request),
			success: function (data) {
				checkServiceAppointmentResult(
					data.payload,
					objectName,
					scheduleId,
					callBack
				);
			},
		});

		//internal callBack for constructing return object.
	}

	//function for updating a service appointment record. On success, it returns the updated object
	function updateServiceAppointmentRecord(
		objectName,
		recordId,
		callBack,
		request,
		scheduleId
	) {
		//sr and base url are in settings.
		//values from settings
		if (!settings.sr.context) {
			return;
		}
		const sr = settings.sr;
		const url = `${sr.context.links.restUrl}connect/scheduling/service-appointments/`;

		//Make call
		Sfdc.canvas.client.ajax(url, {
			client: sr.client,
			contentType: 'application/json',
			method: 'PATCH',
			data: JSON.stringify(request),
			success: function (data) {
				checkServiceAppointmentResult(
					data.payload,
					objectName,
					scheduleId,
					callBack
				);
			},
		});
	}

	function checkServiceAppointmentResult(
		result,
		objectName,
		scheduleId,
		callBack
	) {
		if (!result) {
			result = [
				{
					errorCode:
						'No response from Salesforce. Check Internet Connection.',
					message:
						'No response from Salesforce. Check Internet Connection.',
				},
			];
			callBack(result);
			return;
		} else if (result?.[0]?.errorCode) {
			const message = result[0].message.includes(
				'find any resources for your request'
			)
				? "We couldn't find any resources for your request. This is most likely because the selected resource does not meet the required skill criteria, or the work type isn't in this service area."
				: result[0].message;
			result = [
				{
					errorCode: result[0].errorCode,
					message: message,
				},
			];
			callBack(result);
			return;
		}
		const serviceAppointmentId = result.result.serviceAppointmentId;
		const requests = [{id: '=' + "'" + serviceAppointmentId + "'"}];

		const select =
			'SELECT+Id,ServiceResource.Id,ServiceResource.Name,ServiceAppointment.Id+FROM+AssignedResource';
		const where = `WHERE+ServiceAppointmentId+=+'${serviceAppointmentId}'`;
		const resourceQuery = `${select}+${where}`;
		findServiceRecords(
			objectName,
			callBack,
			requests,
			scheduleId,
			false,
			resourceQuery
		);
	}

	//function for creating a new record in the specified object. On success, it returns the newly created object
	function createRecord(objectName, callBack, request, scheduleId, viewEnd) {
		//sr and base url are in settings.
		//values from settings
		if (!settings.sr.context) {
			return;
		}
		var sr = settings.sr;
		var url = sr.context.links.sobjectUrl + objectName + '/';
		//Make call
		Sfdc.canvas.client.ajax(url, {
			client: sr.client,
			contentType: 'application/json',
			method: 'POST',
			data: JSON.stringify(request),
			success: function (data) {
				checkResult(data.payload);
			},
		});

		//internal callBack for constructing return object.

		function checkResult(result) {
			var requests = [];
			if (!result) {
				result = [
					{
						errorCode:
							'No response from Salesforce. Check Internet Connection.',
						message:
							'No response from Salesforce. Check Internet Connection.',
					},
				];
				callBack(result);
				return;
			}
			if (result.success === true) {
				//we're a repating event, so go back for the instances for this view
				if (request.IsRecurrence) {
					if (objectName === 'Event') {
						requests.push({
							RecurrenceActivityId: '=' + "'" + result.id + "'",
							StartDateTime: '<=' + viewEnd.format(),
						});
					} else if (objectName === 'Task') {
						requests.push({
							RecurrenceActivityId: '=' + "'" + result.id + "'",
							ActivityDate: '<=' + viewEnd.format('YYYY-MM-DD'),
						});
					}
				} else {
					requests.push({id: '=' + "'" + result.id + "'"});
				}
				findRecords(objectName, callBack, requests, scheduleId);
			} else {
				callBack(result);
			}
		}
	}

	//function for updating a record in the specified object. On success, it returns the updated object
	function updateRecord(objectName, recordId, callBack, request, scheduleId) {
		//sr and base url are in settings.
		//values from settings
		if (!settings.sr.context) {
			return;
		}
		const sr = settings.sr;
		const url = `${sr.context.links.sobjectUrl}${objectName}/${recordId}/`;

		//Make call
		Sfdc.canvas.client.ajax(url, {
			client: sr.client,
			contentType: 'application/json',
			method: 'PATCH',
			data: JSON.stringify(request),
			success: function (data) {
				checkResult(data);
			},
		});

		//internal callBack for constructing return object.
		function checkResult(result) {
			if (result.status !== 0 && !result.payload) {
				//no errors find and transfor object
				const requests = [
					{
						id: '=' + "'" + recordId + "'",
					},
				];
				findRecords(objectName, callBack, requests, scheduleId);
				return;
			} else if (result.status === 0) {
				//no internet connection
				//make our own error message
				result.payload = [
					{
						errorCode:
							'No response from Salesforce. Check Internet Connection.',
						message:
							'No response from Salesforce. Check Internet Connection.',
					},
				];
			}
			//errors,
			//payload returns errors
			callBack(result.payload);
		}
	}

	//function for deleting a record in the specified object. On success, it returns {success:true}

	function deleteRecord(objectName, recordId, callBack) {
		//sr and base url are in settings.
		//values from settings
		if (!settings.sr.context) {
			return;
		}
		var sr = settings.sr;
		var url =
			sr.context.links.sobjectUrl + objectName + '/' + recordId + '/';

		//Make call
		Sfdc.canvas.client.ajax(url, {
			client: sr.client,
			contentType: 'application/json',
			method: 'DELETE',
			success: function (data) {
				checkResult(data);
			},
		});

		//internal callBack for constructing return object.

		function checkResult(result) {
			if (result.status === 0) {
				//no internet
				result.payload = [
					{
						errorCode:
							'No response from Salesforce. Check Internet Connection.',
						message:
							'No response from Salesforce. Check Internet Connection.',
					},
				];
			} else if (!result.payload) {
				result.payload = [
					{
						success: true,
					},
				];
				//no errors
			}
			callBack(result.payload);
		}
	}

	//function for getting the userId by name

	function getUserByName(name) {
		var i = 0;
		for (i in settings.users) {
			if (settings.users[i].Name === name) {
				return settings.users[i];
			}
		}
		return '';
	}

	function getUserById(id) {
		var i = 0;
		for (i in settings.users) {
			if (settings.users[i].Id === id) {
				return settings.users[i];
			}
		}
		return '';
	}

	//in array helper
	function inArray(array, val, prop) {
		var i;
		for (i in array) {
			if (array[i] == val) {
				return true;
			}
		}
		return false;
	}

	//general publish function for publishing events to visualforce page

	function publish(event, payload) {
		Sfdc.canvas.client.publish(settings.sr.client, {
			name: event,
			payload: payload,
		});
	}

	function saveSchedules(schedules) {
		settings.schedules = schedules;
	}

	function getUserInfo(property) {
		if (!property) {
			return;
		}
		return settings.userInfo[property];
	}

	//end public functions

	function cleanError(str) {
		return str.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}

	//end all functions
})(
	//fBack settings are set in argument object
	{
		sr: {},
		schedules: [],
		users: [],
		license: {},
		fields: {},
	} //end settings argument
);
